.header {
  width: 100%;
  height: 100%;

  &__container {
    width: 100%;
    // height: 100%;
    position: relative;
  }

  &__textBox {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba($color: #766507, $alpha: 0.3);
    flex-direction: column;
    h1 {
      font-family: "Lilita One", cursive;
      font-size: 5rem;
      margin-bottom: 1rem;
      span {
        color: #ff9100;
      }
      @media screen and (max-width: 1100px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 750px) {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 450px) {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }
    p {
      width: 50%;
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 3rem;
      font-family: "Roboto Mono", monospace;
      @media screen and (max-width: 1200px) {
        font-size: 1.3rem;
        width: 80%;
      }
      @media screen and (max-width: 750px) {
        font-size: 1rem;
        width: 90%;
      }
    }
    button {
      padding: 0.7rem 1.8rem;
      border: 1px solid #ff9100;
      border-radius: 0.5rem;
      background: #ff9100;
      color: #fff;
      font-size: 1rem;
      @media screen and (max-width: 700px) {
        padding: 0.5rem 1.4rem;
        font-size: 0.8rem;
      }
    }
  }
  &__swiper {
    width: 100%;
    height: 85vh;
    @media screen and (max-width: 1000px) {
      height: 80vh;
    }
    @media screen and (max-width: 700px) {
      height: 70vh;
    }
    &-slide {
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
