.universities {
  width: 100%;
  &__container {
    width: 80%;
    margin: 4rem auto;
  }
  &__title {
    text-align: center;

    h1 {
      font-family: "Lilita One", cursive;
      text-transform: uppercase;
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }
    p {
      width: 80%;
      margin: 0 auto;
      font-family: "Roboto Mono", monospace;
      color: #6d6c6c;
    }
  }
  &__categories {
    margin: 2rem 0;
    button {
      margin-right: 2rem;
      padding: 0.4rem 1.2rem;
      border: 1px solid #ffbb5c;
      border-radius: 1rem;
      background-color: transparent;
    }
  }
  &__lists {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  &__university {
    border: 1px solid #ddd;
    border-radius: 1rem;
    overflow: hidden;
    &-img {
      width: 100%;
      height: 30vh;
      margin-bottom: 1rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-info {
      padding: 1rem;
      h5 {
        font-family: "Lilita One", cursive;
        text-transform: uppercase;
        color: #575656;
      }
      span {
        font-weight: bold;
        margin-bottom: 0.5rem;
        display: block;
      }
      p {
        font-family: "Roboto Mono", monospace;
        color: #6d6c6c;
      }
    }
    &-social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        padding: 0;
        li {
          margin-right: 1rem;
          font-size: 1.3rem;
        }
      }
    }
  }
}
