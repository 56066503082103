.gallery {
  background-color: #faf8f1;
  grid-column: full-start/full-end;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);
  grid-gap: 0.5rem;
  padding: 1rem;

  &__item {
    margin: 0;
    position: relative;
    &-text {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #000000, $alpha: 0.2);
      z-index: 10;
      color: #fff;
      font-family: "Lilita One", cursive;
      font-size: 3rem;
      text-transform: uppercase;
    }
    &--1 {
      grid-column: 1/3;
      grid-row: 1/3;
    }
    &--2 {
      grid-column: 3/6;
      grid-row: 1/4;
    }
    &--3 {
      grid-column: 6/7;
      grid-row: 1/3;
    }
    &--4 {
      grid-column: 7/9;
      grid-row: 1/3;
    }
    &--5 {
      grid-column: 1/3;
      grid-row: 3/6;
    }
    &--6 {
      grid-column: 3/5;
      grid-row: 4/6;
    }
    &--7 {
      grid-column: 5/6;
      grid-row: 4/5;
    }
    &--8 {
      grid-column: 6/8;
      grid-row: 3/5;
    }
    &--9 {
      grid-column: 8/9;
      grid-row: 3/6;
    }

    &--11 {
      grid-column: 1/5;
      grid-row: 6/8;
    }

    &--13 {
      grid-column: 5/8;
      grid-row: 5/8;
    }
    &--14 {
      grid-column: 8/9;
      grid-row: 6/8;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
