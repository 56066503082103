.featuredUniversities {
  &__container {
    width: 80%;
    margin: 4rem auto;
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  }
  &__title {
    text-align: center;
    // font-family: "Martian Mono", monospace;
    letter-spacing: 0.3rem;
    text-transform: capitalize;
    color: #606060;
    margin-bottom: 3rem;
  }

  &__swiper {
    width: 100%;
    height: 10rem;
    margin-bottom: 5rem;
    // background-color: red;
    &-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 100%;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__learnmore {
    text-align: right;
    a {
      text-decoration: solid !important;
    }
  }
}
