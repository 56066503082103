.testimonial {
  padding: 4rem 0;
  &__swiper {
    width: 100%;
    height: 100%;
    padding-left: 15rem !important;
    @media screen and (max-width: 1300px) {
      padding-left: 5rem !important;
    }
  }
  &__container {
    // padding-left: 12rem;
  }
  &__swiper-slide {
    font-size: 18px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 2rem;
    // border: 1px solid #ddd;
    border-radius: 1rem;
    margin: 2rem 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-img {
      display: flex;
      align-items: center;

      margin-bottom: 2rem;
      img {
        width: 5rem;
        height: 5rem;
        border-radius: 1rem;
        margin-right: 2rem;
      }
      span {
        color: #6d6c6c;
      }
    }
    &-content {
      color: #6d6c6c;
    }
  }
}
