.about {
  width: 100%;
  &__container {
    width: 80%;
    margin: 4rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-imgBox {
      width: 70%;
      height: 100%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -2rem;
        left: -2rem;
        border: 10px solid #f5c15a;
        z-index: -10;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 100;
      }
    }
  }
  &__content {
    h1 {
      font-family: "Lilita One", cursive;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    p {
      font-family: "Roboto Mono", monospace;
    }
  }
}
