.news {
  &__container {
    padding: 4rem 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 2rem;
    h3 {
      letter-spacing: 0.3rem;
      text-transform: capitalize;
      color: #606060;
      margin-bottom: 2rem;
      font-weight: bolder;
      margin-bottom: 1.1rem;
    }
    p {
      width: 50%;
      text-align: center;
      font-size: 1rem;
      margin: 1rem auto;
      font-family: "Roboto Mono", monospace;
      color: #6d6c6c;
    }
  }
  &__newsList {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    @media screen and (max-width: 1200px) {
      grid-gap: 2rem;
    }
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-news {
      border-radius: 0.5rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: 100%;
      overflow: hidden;
      &-image {
        width: 100%;
        height: 30vh;
      }

      img {
        width: 100%;
        height: 100%;
      }
      &-content {
        padding: 1.5rem;
        div {
          margin-bottom: 1rem;
          span {
            margin-left: 1rem;
          }
        }
        h5 {
          color: #606060;
        }
        p {
          font-family: "Roboto Mono", monospace;
          color: #6d6c6c;
        }
      }
    }
  }
  &__learnmore {
    width: 80%;
    margin: 2rem auto;
    text-align: right;
  }
}
